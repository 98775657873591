import {
  createBaseThemeOptions,
  createUnifiedTheme,
  palettes,
} from '@backstage/theme';
import PoppinsRegular from '../assets/font/Poppins/Poppins-Regular.ttf';

const poppinsRegular = {
  fontFamily: 'Poppins Regular',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 300,
  src: `
    local('Poppins-Regular'),
    url(${PoppinsRegular}) format('ttf'),
  `,
};

export const myTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: palettes.dark,
  }),
  palette: {
    ...palettes.dark,
    primary: {
      main: '#FF6600',
    },
  },
  fontFamily: 'Poppins Regular, sans-serif',
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@font-face': [poppinsRegular],
      },
    },
  },
});

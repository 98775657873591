import React from 'react';
import { makeStyles } from '@material-ui/core';
import TriforkLogo from './logo/logo_wide.svg';

const useStyles = makeStyles({
  img: {
    width: '100%',
  },
});
const LogoFull = () => {
  const classes = useStyles();

  return <img className={classes.img} alt="Trifork" src={TriforkLogo} />;
};

export default LogoFull;
